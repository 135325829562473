import * as React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Social from "../components/social"

import * as styles from "./about.module.scss"

export const query = graphql`{
    aboutHeader: file(
        sourceInstanceName: {eq: "images"},
        relativeDirectory: {eq: "about"}
        name: {eq: "about-header"}
    ) {
        childImageSharp {
            gatsbyImageData(
                quality: 80,
                formats: [WEBP, JPG],
                layout: FULL_WIDTH
            )
        }
    },
    authorImage: file(
        sourceInstanceName: {eq: "images"},
        relativeDirectory: {eq: "about"}
        name: {eq: "author"}
    ) {
        childImageSharp {
            gatsbyImageData(
                quality: 80,
                formats: [WEBP, JPG],
                height: 280,
                width: 280,
                transformOptions: {
                    cropFocus: CENTER
                }
            )
        }
    }
}`


const About = ( props ) => {

    return (
        <Layout>
            <div className={styles.header}>
                <GatsbyImage
                    image={props.data.aboutHeader.childImageSharp.gatsbyImageData}
                    alt=""
                    className={styles.headerImage}
                />
            </div>
            <div className={styles.avatar}>
                <GatsbyImage
                    image={props.data.authorImage.childImageSharp.gatsbyImageData}
                    alt=""
                />
            </div>

            <div className={styles.social}>
                <Social />
            </div>

            <h1 className={styles.title}>Welcome to bryPhoto</h1>
            <div className={styles.content}>
                <p>Selected photographic works of Bryan McDowall.</p>
                <p>I'll use this place to test my photo application, as well as post some of my favourite captures from my many adventures.</p>
                <p>If you're interested in trying ReactPhoto for yourself, you can find more details on the <a href="https://github.com/bryoz/reactphoto">ReactPhoto github page</a>.</p>
            </div>
        
        </Layout>
    )
}

export default About